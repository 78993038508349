<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="640"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 6 }, xs: { span: 8 } }"
      :wrapper-col="{ sm: { span: 18 }, xs: { span: 16 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 租赁点名称 -->
          <a-form-item label="门店名称:">
            <div class="ud-text-secondary">
              {{ data.shopName }}
            </div>
          </a-form-item>
          <a-form-item label="门店logo:">
            <div class="ud-text-secondary">
              <img class="photo" :src="data.logoPic" width="100" />
            </div>
          </a-form-item>
          <!-- 租赁点类型 -->
          <a-form-item label="联系人:">
            <div class="ud-text-secondary">
              {{ data.linkman }}
            </div>
          </a-form-item>
          <a-form-item label="联系电话:">
            <div class="ud-text-secondary">
              {{ data.phone }}
            </div>
          </a-form-item>
          <a-form-item label="营业时间:">
            <div class="ud-text-secondary">
              {{ data.shopHours }}
            </div>
          </a-form-item>
          <!-- 管理人 -->
          <a-form-item label="门店区域:">
            <div class="ud-text-secondary">
              {{ data.shopHours }}
            </div>
          </a-form-item>
          <a-form-item label="详细地址:">
            <div class="ud-text-secondary">
              {{ data.address }}
            </div>
          </a-form-item>
          <a-form-item label="门店坐标:">
            <div class="ud-text-secondary">
              {{ data.latitude }},{{ data.longitude }}
            </div>
          </a-form-item>
          <a-form-item label="门店简介:">
            <div class="ud-text-secondary">
              {{ data.summary }}
            </div>
          </a-form-item>
          <a-form-item label="自提核销:">
            <div class="ud-text-secondary">
              {{ data.isCheck === 0 ? '不支持' : '支持' }}
            </div>
          </a-form-item>
          <a-form-item label="状态:">
            <div class="ud-text-secondary">
              {{ data.status === 0 ? '禁用' : '启用' }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'OperRecordDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {}
  },
  watch: {
    data() {}
  },
  methods: {
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.photo {
  width: 200px;
  height: 100px;
  margin: 20px 0;
}
</style>
